import { Box, Tab, Paper, Stack, Button, List, ListItem, ListItemText} from '@mui/material'
import {Typography, Link, TextField, Grid, Autocomplete, Dialog, Slide} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TabContext, TabList, TabPanel} from '@mui/lab'
import React, {useState, useEffect, forwardRef} from "react";
import CustomPractice from '../practice/custompractice.component'
import Recommendations from '../practice/custompractice.component'
import OngoingPractice from '../practice/custompractice.component'
import MultipleChoiceEngine from '../practice/custompractice.component'
import {useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import {db} from "../../utils/firebase/firebase.utils";
import {collection, onSnapshot} from "firebase/firestore";
import ClassCard from '../../pages/home/ClassCard';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TeacherTopbar({classCode}) {
    const { currentUser } = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('1')
    const [className, setClassName] = useState("")
    const [course, setCourse] = useState("AP Calc BC")
    const [userClasses, setClasses] = useState([])
    const [userList, setUsers] = useState([])

    useEffect(() => 
        onSnapshot(collection(db, "UserCourseData"), (snapshot) => {
            setClasses(snapshot.docs.map((doc) => (doc.data())))
        }), []
    )

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }
 
    const handleClose = () => {
        setOpen(false);
      }
    
    return (
    <Box className="topbar">
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create a class
            </Typography>
                
            </Toolbar>
        </AppBar>
        <Stack spacing={2} direction='column' sx={{alignItems:"center"}}>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Class Name"
                type=""
                variant="standard"
                onChange={(e) => setClassName(e.target.value)}
            />

            <Autocomplete 
                options={["AP Calculus BC","AP Calculus AB"]} 
                renderInput={(params) => <TextField {...params}/>}
                disableClearable
                defaultValue={"AP Calc BC"}
                onChange={(e, newValue) => setCourse(newValue)}
                style={{ width: 300 }}
            />

            <Button variant='outlined'>
                Submit
            </Button>               

        </Stack>

        </Dialog>   

        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label="Tabs" onChange={handleChange} centered>
                    <Tab label="My Students" value='1' />
                    <Tab label="Assign Work" value='2' />
                    <Tab label="Full Exams" value='3' />
                </TabList>
            </Box>

            <TabPanel value='1' style={{display: 'flex', justifyContent:'center'}}>
                <Paper>          
                    <List scrollable>
                            {userClasses.filter((ele) => ele.courseCode === classCode).map(User => 
                                <ListItem component="a">                                                
                                    <ListItemText primary={User.name} secondary='test'/>
                                </ListItem>
                            )}
                    </List>
                </Paper>     
                    {/*
                    .map(userClass => 
                    <Grid item xs={12} md = {6} lg = {4}>
                        <ClassCard className={userClass.className} courseName={userClass.courseName} link={"/"+userClass.courseCode+"/"+currentUser.uid}/>
                    </Grid>
                    )}
                    */}
            </TabPanel>

            <TabPanel value='2'  style={{display: 'flex', justifyContent:'center', alignItems: 'center',}}>
                <Stack spacing={3}>

                <Paper><Recommendations/></Paper>

                <Paper> <OngoingPractice rows={[{a: 'test', b:'test'}]} columnNames={['Name', 'Questions Left']}/> </Paper> 

                    <Paper style={{display:'flex', justifyContent:'center'}}>
                        <CustomPractice/> 
                    </Paper>
                </Stack>

            </TabPanel>

            <TabPanel value='3' style={{display:'flex', justifyContent:'center'}}>
                <MultipleChoiceEngine/>
            </TabPanel>


        </TabContext>

       
    </Box>
    )
}

export default TeacherTopbar;