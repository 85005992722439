import {Button, Stack} from '@mui/material'
import {useState} from 'react'
import ShowQuestion from '.././checkQuestions/showQuestion.component'

const MultiQ = ({questions}) => {
    const [curQuestion, setCurQIndex] = useState(0)

    let numQuestions = []
    for (let i = 0; i < questions.length; i++) {
        numQuestions.push(i)
    }

    return (
        <Stack>
            <ShowQuestion question={questions[curQuestion.toString()]}/>
            <Button>Exit practice</Button>

            <Stack direction="row">
            {numQuestions.map(ele => 
                <Button onClick={() => setCurQIndex(ele)}>{ele+1}</Button>
            )}

            </Stack>
        </Stack>
    )
}

export default MultiQ;