import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Menu, MenuItem, IconButton, Avatar, Box, Tabs, Tab, Button, Stack, Typography} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { css } from '@emotion/react';
import { alpha, styled } from '@mui/material/styles';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../assets/logo.svg';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {Logout, Settings, PersonAdd, Home, AccountCircle, KeyboardArrowDown} from '@mui/icons-material';

import { UserContext } from '../../contexts/user.context.jsx';
import { logout } from "../../utils/firebase/firebase.utils";
import {useContext} from 'react';

  
    const LoginButton = styled(Button)({
      color: 'primary',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '2px solid',
      lineHeight: 1.5,
      marginLeft: "20px",
      marginRight: "20px",
      borderColor: 'black',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        borderColor: 'black',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: 'black',
      },
    });
  
    const MenuButton = styled(Button)({
      color: 'primary',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '3px 8px',
      border: '2px solid',
      lineHeight: 1.5,
      marginLeft: "20px",
      marginRight: "20px",
      borderColor: 'black',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        borderColor: 'black',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: 'black',
      },
    });
  
const LogoBtnStyled = styled(Button)(({theme})=>({
}));

const LisTextStyled = styled(ListItemText)(({theme})=>({
}));

const DrawerStyled = styled(SwipeableDrawer)(({theme})=>({

}));
function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const MobileNavHome = () => {
  const { currentUser } = useContext(UserContext);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 const handleChange = (event, newValue) => {
   setValue(newValue);
 };


  return(
    <React.Fragment>
    <Stack spacing={2}>
    <ElevationScroll>
    <AppBar sx={{ bgcolor: "white" }} elevation={0}>
      <Toolbar>
      <LogoBtnStyled component={Link} to="/" disableRipple onClick={()=>{
        setValue(0);
      }}>
        <Box component={Link} to="/" component="img" sx={{
          height:"3em",
          [theme.breakpoints.down("md")]:{
            height:"3em"
          }
        }} disableRipple onClick={()=>{
          setValue(0);
        }} alt="logo" src={logo}/>
      </LogoBtnStyled>
      <Typography component={Link} to="/home/" variant='h6' sx={{
        flexGrow: 1, textDecoration: "none", color: "black", fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      }}>
        arki academy
      </Typography>
      
      <MenuButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >    
    <AccountCircle color="primary" sx={{minWidth:35,minHeight:35}}/> <KeyboardArrowDown/>
    </MenuButton>
   
    <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            border:'2px solid black',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            minWidth: 150,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="/home/profile">
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/home/">
        <ListItemIcon>
          <Home fontSize="small" />
        </ListItemIcon>
        Home
      </MenuItem>
        <MenuItem component={Link} to="/home/settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={()=>{navigate('/'); logout();}}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>     </Toolbar>
    </AppBar>
    </ElevationScroll>
    <Box sx={(theme) =>({
    border: 0,
    minHeight: 90,
    [theme.breakpoints.down("md")]:{
      minHeight:64,
    }
  })} />

  <Outlet/>
  </Stack>
    </React.Fragment>
  )
}


export default MobileNavHome;