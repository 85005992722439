import React from 'react';
import LandingHome from '../../components/landinghome';

export default function LandingHomePage(){

  return(
    <React.Fragment>
      <LandingHome/>
    </React.Fragment>
  )
}