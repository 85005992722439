import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import MobileNav from './mobile';
import DesktopNav from './desktop';
  
export default function Landing(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  
  return(
    <React.Fragment>
    {matches ? <MobileNav/> : <DesktopNav/>}
    </React.Fragment>
  )
}

