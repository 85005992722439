import LoginForm from "../../components/authentication/login.component";

const Login = () => {
    return (
      <div className="authentication-container">
        <LoginForm/>
      </div>
    );
  };

  export default Login;