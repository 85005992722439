import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useNavigate, Redirect, Navigate} from 'react-router-dom';
import { auth } from "../../utils/firebase/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import MobileNav from './mobile';
import DesktopNav from './desktop';
  
export default function NavigationHome(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [user, loading, error] = useAuthState(auth);

  return(
    <React.Fragment>
    {user ? matches ? <MobileNav/> : <DesktopNav/> : <Navigate to="/"/> }

    </React.Fragment>
  )
}

