import { initializeApp } from "firebase/app";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
  writeBatch,
  addDoc,
  getDoc,
  arrayRemove,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import firebaseConfig from '../../components/firebaseConfig.json'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const checkIfPracticeIdExists = async (coursetype, coursecode, practiceid) => {
    const q = query(collection(db, "PracticeData", coursetype, coursecode), where("practiceid", "==", practiceid));
    const list = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
      console.log(list);
    });
  
    if (list.length === 0) {
      return false;
    }
    else {
      return true;
    }
  }


const createPracticeTest = async(provider, coursetype, coursecode, practiceid, mcquestionlist, frquestionlist, userlist ) =>{
    const dataRef = collection(db, "PracticeData", coursetype, coursecode);
    const ifExists = await checkIfPracticeIdExists(coursetype, coursecode, practiceid);
    if(ifExists===false){
      await addDoc(dataRef, {
        provider: provider,
        coursetype: coursetype,
        coursecode: coursecode,
        practiceid: practiceid,
        mclist: mcquestionlist,
        frlist: frquestionlist,
        userlist: userlist,
      });
    }
    else{
      console.log("it exists")
    }

}

export {
    createPracticeTest
}