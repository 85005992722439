import React from 'react';
import Landing from '../../components/landing';



export default function LandingPage(){

  return(
    <React.Fragment>
      <Landing/>
    </React.Fragment>
  )
}