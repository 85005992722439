import { Box, Tab, Paper, Stack, Typography} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import React, { useEffect, useState } from "react";
import CustomPractice from '../practice/custompractice.component'
import Dashboard from '../dashboard/dashboard.component'
import MultipleChoiceEngine from '../practice/custompractice.component'
import {useContext } from 'react';
import { UserContext } from '../../contexts/user.context';

function Topbar({classCode, uid}) {
    const { currentUser } = useContext(UserContext);

    //console.log(currentUser.uid)


    const [value, setValue] = useState('1')

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    if (currentUser.uid === uid) {
        
      }
      else {
        alert("It looks like you aren't enrolled in this course, redirecting to the home page.")
        //doesn't actually redirect
        return <div></div>
      }    

    return (
    <Box className="topbar">
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label="Tabs" onChange={handleChange} centered>
                    <Tab label="Dashboard" value='1' />
                    <Tab label="Practice" value='2' />
                    <Tab label="Full Exams" value='3' />
                </TabList>
            </Box>
            <TabPanel value='1' style={{display: 'flex', justifyContent:'center'}}>
                <Dashboard classCode={classCode} uid={uid}/>
            </TabPanel>

            <TabPanel value='2'  style={{display: 'flex', justifyContent:'center', alignItems: 'center',}}>
                <Stack spacing={3}>
                    <Paper style={{display:'flex', justifyContent:'center'}}>
                        <CustomPractice/> 
                    </Paper>
                </Stack>

            </TabPanel>

            <TabPanel value='3' style={{display:'flex', justifyContent:'center'}}>
                <MultipleChoiceEngine/>
            </TabPanel>


        </TabContext>
    </Box>
    )
}

export default Topbar;