import React, {UseContext} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material'
import { UserContext } from '../../contexts/user.context';

//rows: [id, Name, Due Date, Score]; columnNames: [name1, name2, ..., name_n]
export default function BasicTable({rows, columnNames}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnNames.map((ele) => <TableCell>{ele}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow 
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            
            {Object.keys(row).map((ele) => <TableCell>{row[ele]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}