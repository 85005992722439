import { initializeApp } from "firebase/app";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
  writeBatch,
  addDoc,
  getDoc,
  arrayRemove,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import firebaseConfig from '../../components/firebaseConfig.json'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

//retrieve all the courses the user is enrolled
const retrieveUserCourses = async (useruid) => {
  const q = query(collection(db, "UserCourseData"), where("uid", "==", useruid));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

//retrieve all the courses the user is enrolled in a specific type
const retrieveUserCoursesCategory = async (useruid, type) => {
  const q = query(collection(db, "UserCourseData"), where("uid", "==", useruid), where("courseType", "==", type));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

//check if a user is enrolled in a specific course
const checkUserEnrollment = async (useruid, code) => {
  const q = query(collection(db, "UserCourseData"), where("uid", "==", useruid), where("courseCode", "==", code));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });

  if (list.length === 0) {
    return false;
  }
  else {
    return true;
  }
}

//enroll user in a course
const userEnroll = async (useruid, username, type, code, courselevel) => {
  const code2Name = {sdifij13213: "Mr. A's AP Calc BC", asdq3213as: "Micheal Morbius' IB HL AA", ugusdhfodsh12o3h: "Random Bio"}
  const code2Course = {sdifij13213: "AP Calc BC", asdq3213as: "IB HL AA", ugusdhfodsh12o3h: "IB Biology HL"}

  const userRef = collection(db, "UserCourseData");
  const condition = await checkUserEnrollment(useruid, code)
  try {
    if (condition === false) {
      await addDoc(userRef, {
        uid: useruid,
        name: username,
        courseType: type,
        courseCode: code,
        courseLevel: courselevel,
        className: code2Name[code],
        courseName: code2Course[code],
        role: "student",
        MCCorrect:  [],
        MCFalse: [],
        MCReview: [],
        FRCorrect: [],
        FRFalse: [],
        FRReview: [],
        CourseContent: [],
        Practice: [],
        Subscription: ""
      });
    }
    else {
      alert("This class already exists for the user");
    }
  } catch {
    alert("This class was not found.")
  }
}

export default userEnroll;


//check if questionid exists
const checkIfQuestionIdExists = async (coursetype, coursecode, questiontype ,questionid) => {
  const q = query(collection(db, "QuestionBank", coursetype, coursecode, questiontype,"main"), where("questionid", "==", questionid));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });

  if (list.length === 0) {
    return false;
  }
  else {
    return true;
  }
}

//add a multiple choice question
const addMCQuestion = async (textq,choices,correctans,questionidq,difficultyq,solutionq,coursetype,coursecode,courselevel,unitq,subtopicsq, needCalculator, adminid) =>{
  const dataRef = collection(db, "QuestionBank", coursetype, coursecode ,"MC", "main");
  const ifExists = await checkIfQuestionIdExists(coursetype, coursecode, "MC", "main", questionidq);
  if(ifExists===false){
    await addDoc(dataRef, {
      questionid: questionidq,
      questionType: "MC",
      courseType: coursetype,
      courseName: coursecode,
      courseLevel: courselevel,
      unit: unitq,
      calculator: needCalculator,
      subtopics: subtopicsq,
      text: textq,
      choices: choices,
      correctans: correctans,
      difficulty: difficultyq,
      solution: solutionq,
      checked: false,
      adminid: adminid
    });
  }
  else{
    console.log("it exists")
  }
}


//update a multiple choice question
const updateMCQuestion = async (textq,choices,correctans,questionidq,difficultyq,solutionq,coursetype,coursecode,courselevel,unitq,subtopicsq, needCalculator, adminid) =>{
  const q = query(collection(db, "QuestionBank", coursetype, coursecode,"MC", "main"), where("questionid", "==", questionidq));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.id);
  });
  const questionDocName = list[0];
  console.log(questionDocName);
  const dataRef = doc(db, "QuestionBank", coursetype, coursecode,"MC", "main", questionDocName);

  await updateDoc(dataRef, {
    questionid: questionidq,
    questionType: "MC",
    courseType: coursetype,
    courseName: coursecode,
    courseLevel: courselevel,
    unit: unitq,
    calculator: needCalculator,
    subtopics: subtopicsq,
    text: textq,
    choices: choices,
    correctans: correctans,
    difficulty: difficultyq,
    solution: solutionq,
    checked: false,
    adminid: adminid
  });
}

//add a free response question
const addFRQuestion = async (adminuid,textq,correctans,questionidq,difficultyq,imageq,solutionq,coursetype,coursecode,courselevel,unitq,subtopicsq) =>{
  const dataRef = collection(db, "QuestionBank", coursetype, coursecode, "main" ,"FR");

  const ifExists = await checkIfQuestionIdExists(coursetype, coursecode, "FR" ,questionidq);

  if(ifExists===false){
    await addDoc(dataRef, {
      questionid: questionidq,
      adminid: adminuid,
      questionType: "FR",
      courseType: coursetype,
      courseCode: coursecode,
      courseLevel: courselevel,
      unit: unitq,
      subtopics: subtopicsq,
      text: textq,
      correctanswer: correctans,
      difficulty: difficultyq,
      image: imageq,
      solution: solutionq,
      checked: false,
    });
  }
  else{
    console.log("questionid already exists")
  }
}

//update a free response question
const updateFRQuestion = async (adminuid,textq,correctans,questionidq,difficultyq,imageq,solutionq,coursetype,coursecode,courselevel,unitq,subtopicsq) =>{
  const q = query(collection(db, "QuestionBank", coursetype, coursecode, "main", "FR"), where("questionid", "==", questionidq));
  const list = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.id);
  });
  const questionDocName = list[0];
  const dataRef = doc(db, "QuestionBank", coursetype, coursecode, "main", "FR",questionDocName);

  await updateDoc(dataRef, {
    questionid: questionidq,
    adminid: adminuid,
    questionType: "FR",
    courseType: coursetype,
    courseCode: coursecode,
    courseLevel: courselevel,
    unit: unitq,
    subtopics: subtopicsq,
    text: textq,
    correctanswer: correctans,
    difficulty: difficultyq,
    image: imageq,
    solution: solutionq,
    checked: false,
  });
}

export {
  checkIfQuestionIdExists,
  addMCQuestion,
  addFRQuestion,
  updateMCQuestion,
  updateFRQuestion,
  retrieveUserCourses,
  retrieveUserCoursesCategory,
  checkUserEnrollment,
  userEnroll,
};