import ResetForm from "../../components/authentication/reset.component";

const Reset = () => {
    return (
      <div className="authentication-container">
        <ResetForm />
      </div>
    );
  };

  export default Reset;