import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useNavigate, Redirect, Navigate} from 'react-router-dom';

import MobileProfile from './mobile';
import DesktopProfile from './desktop';
  
export default function Profile(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));


  return(
    <React.Fragment>
    { matches ? <MobileProfile/> : <DesktopProfile/>}
    </React.Fragment>
  )
}

