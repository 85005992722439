import { initializeApp } from "firebase/app";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
  writeBatch,
  addDoc,
  getDoc,
  arrayRemove,
  updateDoc,
  arrayUnion,
  increment,
} from "firebase/firestore";
import firebaseConfig from '../../components/firebaseConfig.json'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// upload number of questions in each sub
const updateNumberofQuestionsBySub = async() =>{
  const apcalcref = doc(db, "Meta", "APCalculus");
  await updateDoc(apcalcref, {
    '3.2':10
});
}

const apcalcList = ["1.1","1.2","1.3","1.4","1.5","1.6","1.7","1.8","1.9","1.10","1.11","1.12","1.13","1.14","1.15","2.1","2.2","2.3","2.4","2.5","2.6","2.7","2.8","2.9","3.1","3.2","3.3","3.4","3.5",
"3.6","4.1","4.2","4.3","4.4","4.5","4.6","4.7","5.1","5.2","5.3","5.4","5.5","5.6","5.7","5.8","5.9","5.10","5.11","5.12","6.1","6.2","6.3","6.4","6.5","6.6","6.7","6.8","6.9","6.10","6.11","6.12","6.13","6.14","7.1"
,"7.2","7.3","7.4","7.5","7.6","7.7","7.8","7.9","8.1","8.2","8.3","8.4","8.5","8.6","8.7","8.8","8.9","8.10","8.11","8.12","8.13"
,"9.1","9.2","9.3","9.4","9.5","9.6","9.7","9.8","9.9","10.1","10.2","10.3","10.4","10.5","10.6","10.7","10.8","10.9","10.10","10.11","10.12","10.13","10.14","10.15"];

const updateNumberofQuestions = async () => {
  apcalcList.forEach(async (element) => {
    const apcalcref = doc(db, "Meta", "APCalculus");
    const q = query(collection(db, "QuestionBank", "AP", "Calculus", "MC", "main",), where("subtopics", "array-contains", element));
    const list = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
   });
    console.log(list);
    const obj = {};
    obj[element] = list.length;
    await updateDoc(apcalcref, obj);
  });
}

const updateNumQuestionsIndividually = async (newList) => {
  const apcalcref = doc(db, "Meta", "APCalculus");
  newList.forEach(async (element) => {
    const obj = {};
    obj[element] = increment(1);
    await updateDoc(apcalcref, 
     obj);
  });
}

const retrieveNumberofQuestionsBySub = async() =>{
  const q = query(doc(db, "Meta", "APCalculus"));
  const qW = await getDoc(q);
  const qlist = []
  qlist.push(qW.data());
  return qlist[0]
}

//read question by unit 
const retrieveQuestionsByUnitExceptList = async (coursetype, coursecode, questiontype, unitlist, exceptlist) => {
    const exceptqlist = [exceptlist]
    exceptqlist.push("fjwjdqwjdjqdjqwkdqkwdqkq");
    const q = query(collection(db, "QuestionBank", coursetype, coursecode, "main",questiontype), where("questionid", "not-in", exceptqlist));
    const list = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if(unitlist.includes(doc.data().unit)){
        list.push(doc.data());
      }
    })
    return list;
  }

//returns 2D array of the number of questions for each subTopics entered
/*
Example output: 
[ [ '1.3', 12 ],
  [ '1.6', 0 ],
  [ '1.1', 17 ],
  [ '1.4', 22 ],
  [ '1.2', 24 ],
  [ '1.5', 24 ] ]

*/
const allocate_questions = (units, numQuestions, unitsData, res=[]) => {
  const numUnits = units.length
  const equalPortion = Math.floor(numQuestions/numUnits) //integer division

  let remainingUnits = units.slice()
  let numRemainingQuestions = numQuestions

  let floatingSum = 0 
  //check if sum of units is enough for the number of questions provided by the user

  let allPossible = true //if each unit includes enough questions to equally share
  let unit

  for (let i = 0; i < numUnits; i++) {
      unit = units[i]
      if (unitsData[unit] < equalPortion) {
          remainingUnits.splice(remainingUnits.indexOf(unit), 1)
          numRemainingQuestions -= unitsData[unit]
          res.push([unit, unitsData[unit]])

          allPossible = false
      }
  floatingSum += unitsData[unit]

  }


  if (floatingSum < numQuestions) {
      alert(`Unfortunately we don't have a total of ${numQuestions} from these units in our question bank, however we can provide this many questions: ${floatingSum}`)
      return    
  }
  
  if (allPossible) {
      for (let i in remainingUnits) {
          res.push([remainingUnits[i], equalPortion])
      } 
      
      return res
  }
  return allocate_questions(remainingUnits, numRemainingQuestions, unitsData, res=res)
}


//retrieve a specific number of correct/false/review questions in specific units
const retrieveUserQuestionsbyUnit = async (useruid, coursetype, courseName, questiontype, subtopiclist, numberofQ) =>{
    const userPrimaryQuestionList = [];
    const userCorrectQuestionList = [];
    var questionData = [];
    const q = query(collection(db, "UserCourseData"), where("uid", "==", useruid), where("courseName", "==", courseName));
    const querySnapshot = await getDocs(q);
    if(questiontype==="MC"){
            querySnapshot.forEach((doc) => {
                userPrimaryQuestionList.push(doc.data().MCFalse);
                userPrimaryQuestionList.push(doc.data().MCReview);
                userCorrectQuestionList.push(doc.data().MCCorrect);
              });
        }
    else if(questiontype==="FR"){
            querySnapshot.forEach((doc) => {
                userPrimaryQuestionList.push(doc.data().FRFalse);
                userPrimaryQuestionList.push(doc.data().FRReview);
                userCorrectQuestionList.push(doc.data().FRCorrect);
              });
        }

    const questionIdList = userPrimaryQuestionList[0].filter(t=> !userPrimaryQuestionList[1].includes(t)).concat(userPrimaryQuestionList[1]);
    const correctQuestionList = userCorrectQuestionList[0];
    const questionsUnitFilter = [];
    var userQListFinal = [];

    subtopiclist.forEach(async (doc)=>{
      const q2 = query(collection(db, "QuestionBank", "AP", "Calculus", "MC", "main" ), where("subtopics", "array-contains", doc));
      const query2Snapshot = await getDocs(q2);
      query2Snapshot.forEach((doc) => {
        if(!correctQuestionList.includes(doc.data().questionid)){
          questionData.push(doc.data());
        }
        });
    })

    return questionData;
} 

//check if an mc question answer is true
    const controlMCQuestion = async (userAns, courseType, courseCode, questionid) =>{
    const q = query(collection(db, "QuestionBank", courseType, courseCode, "main", "MC"), where("questionid", "==", questionid));
    const list = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push(doc.data().correctanswer);
    });
    const correctAns = list[0];
    console.log(correctAns);
    if(correctAns === userAns){
      return "correct";
    }
    else if(correctAns !== userAns){
      return "false";
    }
  }
  
  //solve a question
  const solveQuestion = async (useruid, code, questionType, questionId, result) =>{
    const q = query(collection(db, "UserCourseData"), where("uid", "==", useruid), where("courseName", "==", code));
    const list = [];
    const userDetails = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push(doc.id);
      userDetails.push(doc.data());
    });
    const userDocId = list[0];
    
    const docRef = doc(db, "UserCourseData", userDocId)
    if(questionType === "MC"){
      if(result === "correct")
      {
        if (userDetails[0].MCFalse.includes(questionId)){
          await updateDoc(docRef, {
            MCFalse : arrayRemove(questionId)
         }
         );
        }
        await updateDoc(docRef, {
         MCCorrect : arrayUnion(questionId),
      }
      );
      }
      else if(result === "false"){
        await updateDoc(docRef, {
          MCFalse : arrayUnion(questionId),
       }
       );
      }
      else if(result === "review"){
        await updateDoc(docRef, {
          MCReview : arrayUnion(questionId),
       }
       );
      }
    }
    else if(questionType === "FR"){
      if(result === "correct")
      {
        if (userDetails[0].MCFalse.includes(questionId)){
          await updateDoc(docRef, {
            FRFalse : arrayRemove(questionId)
         }
         );
        }
        await updateDoc(docRef, {
         FRCorrect : arrayUnion(questionId),
      }
      );
      }
      else if(result === "false"){
        await updateDoc(docRef, {
          FRFalse : arrayUnion(questionId),
       }
       );
      }
      else if(result === "review"){
        await updateDoc(docRef, {
          FRReview : arrayUnion(questionId),
       }
       );
      }
    }
  }

  export{
    updateNumQuestionsIndividually,
    updateNumberofQuestions,
    retrieveNumberofQuestionsBySub,
    updateNumberofQuestionsBySub,
    retrieveUserQuestionsbyUnit,
    retrieveQuestionsByUnitExceptList,
    solveQuestion,
    controlMCQuestion,
  };