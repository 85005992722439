import React, {useState} from 'react'
import {Autocomplete, TextField, Typography, Box, RadioGroup, Radio, FormControlLabel, Stack, Checkbox, Button} from '@mui/material' 
import Tex2SVG from "react-hook-mathjax";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';    
import APCALC from '../unitperclass/apcalc.json'
import firebaseConfig from '../firebaseConfig.json'

import { initializeApp } from "firebase/app";
import {getFirestore,query,getDocs,collection,where,addDoc} from "firebase/firestore";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function FR() {
  const course = "APCalc"

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);  
  const colRef = collection(db, course + "Questions")

  const [qText, setText] = useState('')

  const [difficulty, setDifficulty]= useState('Medium')

  let subTopicsArray = []

  for (const unit in APCALC) {
      subTopicsArray.push([APCALC[unit]["name"], APCALC[unit]["subTopics"]])
  }
  
  const metaTopics = [] //topics user initially selects from
  subTopicsArray.map((pairs) => metaTopics.push(pairs[0]))

  const subTopics = new Map()
  subTopicsArray.map((pair) => subTopics.set(pair[0], pair[1]))

  const [possibleTopics, setSelected] = useState(subTopicsArray[0][1]) //sub-topics that user is able to pick from
  const [solution, setSolution] = useState('')
  const [checkedBoxes, setChecked] = useState([])

  const handleChange = (e, newValue) => {
      setSelected(subTopics.get(newValue))
  }
  
  const handleTextChange = (e) => {
    setText(e.target.value)  
  }

  const handleSubmit = () => {
    const subTopicID = checkedBoxes[0].split(' ')[0].replace('.', '0')
    let r = (Math.random() + 1).toString(36).substring(5) //random 5 character string
 
    alert('Question successfully submitted, thank you!')

    addDoc(colRef, {
      id: `${subTopicID.replace('.', '0') + 'APCALC' + r}`,
      text: qText,
      subTopics: checkedBoxes,
      type: 'FR',
      answer1: 'FR',
      answer2: 'FR',
      answer3: 'FR',
      answer4: 'FR',
      trueAnswer: 'FR',
      solution: solution,
      image: '',
      difficulty: difficulty,
      checked: false
    })
  }


  return (
    <Stack>
      <form noValidate autoComplete='off'>     
        <TextField
          label='Image'
          variant='outlined'
          fullWidth
          />   

        <TextField
            label='Question Text'
            variant='outlined'
            helperText='Write equations in LaTex and inside $#$, for example: $#x^2$'
            multiline
            rows={4}
            fullWidth
            required
            onChange={handleTextChange}
        />  

        <TextField
            label='Solution'
            variant='outlined'
            multiline
            rows={4}
            fullWidth
            required
            onChange={(e) => setSolution(e.target.value)}
        /> 

        <Autocomplete 
            options={metaTopics} 
            renderInput={(params) => <TextField {...params}/>}
            disableClearable
            defaultValue={subTopicsArray[0][0]}
            onChange={handleChange}
            style={{ width: 300 }}
            />
            
            <Autocomplete
                multiple
                onChange={(e, selected) => setChecked(selected)}
                disableClearable
                id="checkboxes"
                options={possibleTopics}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                    </li>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Sub-Topics" placeholder="All" />
                )}
            />

        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={["Easy", "Medium", "Hard"]}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Difficulty" />}
            onChange={(e, selected) => setDifficulty(selected)}
        />

        <Typography>What the student will see:</Typography>

        <div>
        {qText.split('$').map((phrase) => 
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)}
        </div>

        <Button variant="contained" onClick={handleSubmit}>Submit Question</Button>


      </form>
    </Stack>
  )
}

export default FR