import React from 'react';
import NavigationHome from '../../components/navigation';



export default function NavigationPage(){

  return(
    <React.Fragment>
      <NavigationHome/>
    </React.Fragment>
  )
}