import {Button} from '@mui/material';

const ApproveQuestion = ({question}) => {
    const approve = () => {
        alert('currently unfinished')
    }

    return (
    <>
        {!question.checked ? <Button onClick={() => approve()}>
        The above question is approved
        </Button> : <></>}
    </>
    )
}

export default ApproveQuestion;