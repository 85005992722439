import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '##FFFFFF',
      },
    },
});

export default Theme;
