import SubmitQuestion from '../../components/questionsub/submitquestion.component'
import {Stack} from '@mui/material'
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../utils/firebase/firebase.utils";

function Admin({role}) {
    if (role === 'admin') {
        return (
            <Stack>
                <SubmitQuestion/>
            </Stack>
            )        
    }
    else {
        return (
            <div>
                Access denied
            </div>
    
        ) 
    }

}

export default Admin;