import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import Home from './pages/home/home.component';
import TeacherHome from "./pages/home/teacher.home";
import Dashboard from './pages/dashboard/dashboard.component';
import Landing from './pages/landing/landingpage';
import LandingHomePage from './pages/landinghome/landinghomepage';
import NavigationPage from './pages/navigation/navigationhome';
import ProfilePage from './pages/profile/profilepage';

import Login from './pages/login/login.component';
import Register from './pages/register/register.component';
import Reset from './pages/reset/reset.component';
import Admin from './pages/admin/admin.component';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './components/theme';


import CheckQ from './components/checkQuestions/checkQ.component';
import { db } from "./utils/firebase/firebase.utils";
import { collection, onSnapshot } from "firebase/firestore";
import Deneme from './pages/deneme/deneme.component';
import TeacherTopbar from './components/topbar/teacher.topbar';
import { UserContext } from './contexts/user.context';


function App() {
  const [userClasses, setClasses] = useState([]);
  const { currentUser } = useContext(UserContext)

  useEffect(() =>
    onSnapshot(collection(db, "UserCourseData"), (snapshot) => {
      setClasses(snapshot.docs.map((doc) => (doc.data())))
    }), []
  )
  let userRoles, userName;

  if (currentUser) {
    userRoles = currentUser.roles;
    userName = currentUser.name;
  }
  else {
    userRoles = "";
    userName = "";
  }

  console.log(currentUser)

  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <Routes>

          <Route path='/' element={<Landing />}>
            <Route index element={<LandingHomePage/>}/>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/reset' element={<Reset />} />
          </Route>

          <Route path='/home' element={<NavigationPage />}>
            <Route index element={userRoles === "teacher" ? <TeacherHome /> : <Home />} />
            <Route path='deneme' element={<Deneme />} />
            <Route path='teacher' element={<TeacherTopbar />} />
            <Route path='profile' element={<ProfilePage/>} />
            <Route path='admin' element={<Admin role={userRoles} />} />
            <Route path='checkQuestions' element={<CheckQ />} />
            <Route path="dashboard" element={() => { <h1>merhaba</h1> }} /> {/*temporary, will be replaced by personalized dashboard*/}
            {userClasses.map((ele) => <Route path={ ele.courseCode + "/" + ele.uid} element={<Dashboard classCode={ele.courseCode} uid={ele.uid} />} />)}           
          </Route>

        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
