import { Outlet } from 'react-router-dom';
import {useState, forwardRef, useContext, useEffect, Route} from 'react';
import {Typography, Link, Box, Button, TextField, Grid, Autocomplete, Dialog, Stack} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import userEnroll from '../../utils/firebase/course.utils'
import {db} from "../../utils/firebase/firebase.utils";
import { UserContext } from '../../contexts/user.context';
import {collection,onSnapshot} from "firebase/firestore";
import ClassCard from './ClassCard'
import {styled} from '@mui/material/styles';
import {CalcAB, CalcBC} from '../.././components/landinghome/desktop'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Title = styled(Typography)({
  color: 'black',
  fontSize: 30,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

function Copyright(props) {
  const [open, setOpen] = useState(false);
  const [classCode, setClassCode] = useState("");
  const { currentUser } = useContext(UserContext);
  const [userClasses, setClasses] = useState([]);
    useEffect(() => 
    onSnapshot(collection(db, "UserCourseData"), (snapshot) => {
      setClasses(snapshot.docs.map((doc) => (doc.data())))
    }), 
    []
  )
  
  if(currentUser){


  const handleClickOpen = () => {
    console.log(currentUser.uid);
    userEnroll(currentUser.uid, "asd", "type", classCode, "courselevel", "Deniz Kavi's Test Class");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Title>My Classes</Title>

    <Box textAlign='center'>

      <Button variant='outlined'  onClick={() => setOpen(true)}>
          Join a Class
      </Button>
    </Box>

    <Grid container spacing={3}>
      {userClasses.filter((ele) => ele.uid === currentUser.uid).map(userClass => 
      <Grid item xs={12} md = {6} lg = {4}>
        <ClassCard className={userClass.className} courseName={userClass.courseName} link={"/home" + "/"+userClass.courseCode+"/"+currentUser.uid}/>
      </Grid>
      )}
    </Grid>

    <Title>Available Classes</Title>      

      <Grid alignItems="flex-start" container>
        {CalcAB}
        {CalcBC}
      </Grid>

    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Join a class
          </Typography>
            
        </Toolbar>
      </AppBar>
      <Stack spacing={2} direction='column' sx={{alignItems:"center"}}>
        <Typography>Ask your teacher for your class code:</Typography>

        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Class Code"
            type=""
            variant="standard"
            onChange={(e) => setClassCode(e.target.value)}
          />

          <Button variant='outlined' onClick={handleClickOpen}>
              Submit
          </Button>

          <Typography>Or sign up to one of our indiviual plans:</Typography>
          
            <Autocomplete 
                options={["AP Calculus BC","AP Calculus AB"]} 
                renderInput={(params) => <TextField {...params}/>}
                disableClearable
                defaultValue={"AP Calculus BC"}
                onChange={() => false}
                style={{ width: 300 }}
            />
      </Stack>

    </Dialog>

    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://arki.academy/">
        Arki Academy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </>
  );
  }
}

  
const Home = () => {
    return(
        <div>
        <Copyright sx={{ mt: 5 }} />
        <Outlet/>        
        </div>
    );
}

export default Home;