import RegisterForm from "../../components/authentication/register.component";

const Register = () => {
    return (
      <div className="authentication-container">
        <RegisterForm />
      </div>
    );
  };

  export default Register;