import TopBar from '../../components/topbar/topbar.component';
import TeacherTopbar from '../../components/topbar/teacher.topbar';
import { UserContext } from '../../contexts/user.context';
import {useContext} from 'react';

const Dashboard = ({classCode, uid}) => {
    const { currentUser } = useContext(UserContext);
        
    if (currentUser)
    {
    if (currentUser.roles === "teacher") {
        return(
            <TeacherTopbar classCode={classCode}/>
        )
    }

    else {
        return (
            <>
                <TopBar classCode={classCode} uid={uid}/>
            </>
        )
    }
    }
   
}

export default Dashboard;
