import React, { useEffect, useState } from "react";
import GoogleButton from 'react-google-button';
import {Button, CssBaseline, Avatar, TextField, FormControlLabel, Checkbox, Grid, Link, Box,Typography, Container, Stack} from '@mui/material';
import { Link as _link, useNavigate } from 'react-router-dom';
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../utils/firebase/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import {styled} from '@mui/material/styles';

function Login(){
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) navigate("/home/");
  }, [user, loading]);

    const handleSubmit = (event) =>
    {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        logInWithEmailAndPassword(data.get("email"),data.get("password"))
    };

    const StyledTextTwo = styled(Typography)({
      color: 'gray',
      fontSize: 20,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    });

    return( <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 2,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      wrap="nowrap"
      sx={{mb:5,mt:2,}}
    >

    </Grid>

      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      </Avatar>
      <Typography component="h1" variant="h5">
        Log in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, textTransform:'none', }}
        >
          Log In
        </Button>
        <Grid container spacing={0}
        alignItems="center"
        justifyContent="center"
        sx = {{mt:3,mb:3}}
        >
        <Stack direction="column" sx={{justifyContent:'center', alignItems:'center',display:'flex'}} spacing={2}>
        <StyledTextTwo>Or</StyledTextTwo>
       <GoogleButton onClick={signInWithGoogle}/>
       </Stack>        </Grid>
        <Grid container>
          <Grid item xs>
            <Link href="/reset" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>)
}

export default Login;
