import {useContext} from 'react';
import React from 'react';
import {AppBar, Toolbar, Typography, Stack, Button, Box} from '@mui/material';
import { Outlet, Link} from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { logout } from '../../utils/firebase/firebase.utils';
import { styled } from '@mui/material/styles';
import logo from '../../assets/logo.svg';
import useScrollTrigger from '@mui/material/useScrollTrigger';



function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
  

const GetStartedButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
  });
  

  const LoginButton = styled(Button)({
    color:'black',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 1.5,
    borderColor: 'black',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      borderColor: 'black',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: 'black',
    },
  });
  
export const MobileNav = () => {
    const { currentUser } = useContext(UserContext);

    if(currentUser){
      return(
        <React.Fragment>
        <Stack spacing={2}>
        <ElevationScroll>
        <AppBar sx={{ bgcolor: "white" }} elevation={0}>
        <Toolbar>
        <Button component={Link} to="/" disableRipple >
            <Box component={Link} to="/" component="img" sx={{
              height:"2.5em",
            }} disableRipple alt="logo" src={logo}/>
          </Button>

          <Typography component={Link} to="/" variant='h6' sx={{flexGrow:1, textDecoration: "none", color:"black",fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(','),}} nowrap="true">
                arki academy 
            </Typography>
            <Stack direction='row' spacing={2}>
            <LoginButton onClick={logout} disableRipple nowrap="true">
             Log out
            </LoginButton>  
            </Stack>
        </Toolbar>
         </AppBar>
       </ElevationScroll>
       <Box sx={{height:50,width:300}}></Box>
          <Outlet/>
        </Stack>
     </React.Fragment>
    )
    }
    else {
      return(
        <React.Fragment>
        <Stack spacing={2}>
        <ElevationScroll>
        <AppBar sx={{ bgcolor: "white" }} elevation={0}>
        <Toolbar>
        <Button component={Link} to="/" disableRipple nowrap="true">
            <Box component={Link} to="/" component="img" sx={{
              height:"2.8em",
            }} disableRipple alt="logo" src={logo}/>
          </Button>

          <Typography component={Link} to="/" variant='h6' sx={{flexGrow:1, textDecoration: "none", color:"black",     fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(','),}}>
                arki academy 
            </Typography>
            <Stack direction='row' spacing={2}>
            <LoginButton component={Link} to="/login" disableRipple nowrap="true">
             Log in
            </LoginButton>
            </Stack>
        </Toolbar>
         </AppBar>
       </ElevationScroll>
       <Box sx={{height:50,width:300}}></Box>
        </Stack>
        <Outlet/>
     </React.Fragment>
    )
    }
    
}

export default MobileNav;