import React, {useEffect, useState} from 'react'
import {collection, onSnapshot, getDocs} from "firebase/firestore";
import {db} from "../../utils/firebase/firebase.utils";
import ShowQuestion from './showQuestion.component';

//need uid to make sure that only certain people can review questions
function CheckQ() {
  const [unreviewed, setUnreviewed] = useState([])
  
  useEffect(() => 
    onSnapshot((collection(db, "QuestionBank", "AP", "Calculus" ,"MC", "main")), (snapshot) => {
      setUnreviewed(snapshot.docs.map((doc) => (doc.data())))          
    }), []
  )

  
  return (
    <div>
      {unreviewed.map((question) =>
        (<ShowQuestion question={question}/>))
      }        
    </div>

  )
}

export default CheckQ;
