import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Container,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";

const FooterTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  marginBottom: "1em",
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));

const SubscribeTf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: 'white',
  },
  ".MuiInput-root": {
    borderBottom: `white`,
  },
  color: 'white',
  input: {
    color: "white"
  },
  width: '40ch'
}));

const StyledTextThree = styled(Typography)({
    color: 'primary.main',
    fontSize: 25,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  });

export default function Footer() {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' }
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6} lg={4} sx={{mr:3}}>
        <StyledTextThree variant="body1" sx={{textTransform:'none'}}> About Us </StyledTextThree>
          <Typography variant="caption2">
            Arki Academy is a question bank, a revision tool and a teacher to answer your questions. It is a learning platform that can help you to maximize your score in the shortest time possible. <br/> <br/>
          </Typography>
          <Typography variant="caption2">
          Courses on Arki offers fully updated syllabus of the tests and the most recent exam-style question types. Our approach is basic: we help students to master every question type that they can see on a real exam. 
          </Typography>
          <Box
            sx={{
              mt: 2,
              color: 'primary.main',
            }}
          >
            <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <InstagramIcon />
          </Box>
        </Grid>
        <Grid item md={6} lg={2} sx={{mr:2}}>
          <StyledTextThree variant="body1" sx={{textTransform:'none'}}> Platform </StyledTextThree>
          <List>
            <ListItemText>
              <Typography lineHeight={2} variant="caption2">
                Current Courses
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography lineHeight={2} variant="caption2">
                Most Asked Questions
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography lineHeight={2} variant="caption2">
                Blog
              </Typography>
            </ListItemText>
          </List>
        </Grid>
        <Grid item md={6} lg={2} sx={{mr:2}}>
        <StyledTextThree variant="body1" sx={{textTransform:'none'}}> Info </StyledTextThree>
        <List>
          <ListItemText>
            <Typography lineHeight={2} variant="caption2">
              Our Story
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography lineHeight={2} variant="caption2">
              Privacy &amp; Policy
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography lineHeight={2} variant="caption2">
              Terms &amp; Conditions
            </Typography>
          </ListItemText>
        </List>
      </Grid>
        <Grid item md={6} lg={4}>
        <StyledTextThree variant="body1" sx={{textTransform:'none'}}> Contact Us </StyledTextThree>
        <Stack>
            <SubscribeTf
              sx={{mt:1,mb:1,mt:2}}
              color="secondary"
              label="Email address"
              variant="standard"
              focused
            />        
            <Button
              startIcon={<SendIcon sx={{ color: "white", textTransform: 'none'}} />}
              sx={{ mt: 2, mb: 4, color:'white', textTransform:'none'}}
              variant="contained"
            >
            Subscribe to our mailing list
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
