import React, {useEffect} from 'react';
import { Typography, Stack, Button,Grid, Paper, Divider} from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from './footer';
import {Link} from 'react-router-dom';
import Login from '../authentication/login.component';
import ArkiCourseCard from './ArkiCourseCard';
import calcab from './calcab.jpeg';
import calcbc from './calcbc.jpeg';

const StyledTextOne = styled(Typography)({
  color: 'black',
  fontSize: 60,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

const StyledTextTwo = styled(Typography)({
  color: 'gray',
  fontSize: 30,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

const StyledTextThree = styled(Typography)({
  color: 'primary.main',
  fontSize: 35,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

const StyledTextFour = styled(Typography)({
  color: 'primary.main',
  fontSize: 25,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});


const GetStartedButton = styled(Button)({
  color: 'white',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 20,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
});

const CalcAB = <ArkiCourseCard name='AP®︎ Calculus AB' img={calcab} description={''}/>   
const CalcBC = <ArkiCourseCard name='AP®︎ Calculus BC' img={calcbc} description={''}/>   

export {CalcAB, CalcBC}

const LandingHomeDesktop = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <React.Fragment>
      <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', m: 4 }} spacing={3}>
          <StyledTextOne align="center">
            Testing has never been this easy.
          </StyledTextOne>
          <Stack sx={{ maxWidth: 700, justifyContent: 'center', alignItems: 'center' }}>
            <StyledTextTwo align="center">
              Achieve the best score possible, with Arki Academy.
            </StyledTextTwo>
          </Stack>
          <GetStartedButton component={Link} to="/register">
            Get Started Now (It's free!)
          </GetStartedButton>
        </Stack>
        <Stack sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems:'center', mt:3 }}>
            <StyledTextFour>Current Courses</StyledTextFour>
          </Stack>

          <Grid sx={{flexGrow:1,m:3, mb:5}} container justifyContent="center" spacing={2}>
          <Grid key={1} component={Link} to="/apcalcab" sx={{textDecoration:'none'}} item>
          <Stack sx={{}}>
            <ArkiCourseCard name='AP®︎ Calculus AB' img={calcab} description={''}/>   

          {/*
          <Paper sx={{height:200, width:200,display:'flex', justifyContent:'center', alignItems:'center',}}>
          <StyledTextFour>AP Calculus AB</StyledTextFour>
          </Paper>
          */}
          
          </Stack>
        </Grid>
          <Grid key={1} component={Link} to="/apcalcbc" sx={{textDecoration:'none'}} item>
          <Stack sx={{}}>
          <ArkiCourseCard name='AP®︎ Calculus BC' img={calcbc} description={''}/>            
          </Stack>
        </Grid>
      </Grid>
          <StyledTextThree align="center">
            Revise. Practice. Excel. That's our motto.
          </StyledTextThree>
        </Stack>
        <Login/>
        </Stack>
      <Footer />
    </React.Fragment>
  );
}

export {StyledTextOne,StyledTextTwo,StyledTextThree,StyledTextFour};
export default LandingHomeDesktop;