import React, {useState, useContext} from 'react'
import {Autocomplete, TextField, Typography, Box, RadioGroup, Radio, FormControlLabel, Stack, Checkbox, Button, FormGroup, 
Dialog, AppBar, Toolbar, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CalculateIcon from '@mui/icons-material/Calculate';
import Tex2SVG from "react-hook-mathjax";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import APCALC from '../unitperclass/apcalc.json';
import {addMCQuestion} from '../../utils/firebase/course.utils.jsx';
import {updateNumQuestionsIndividually} from '../../utils/firebase/qbank.utils.jsx';
import firebaseConfig from '../firebaseConfig.json'
import { initializeApp } from "firebase/app";
import {getFirestore,query,getDocs,collection,where,addDoc} from "firebase/firestore";
import {getStorage, ref, uploadBytes} from 'firebase/storage';
import { UserContext } from '../../contexts/user.context';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MC() {
  const course = "APCalc"
  const [imageFile, setImageUpload] = useState(null)

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app)

  const { currentUser } = useContext(UserContext)

  const [qText, setText] = useState('')
  const [needCalculator, setCalculator] = useState(false)

  const [difficulty, setDifficulty]= useState('Medium')

  const [a1, set1] = useState('Answer 1')
  const [a2, set2] = useState('Answer 2')
  const [a3, set3] = useState('Answer 3')
  const [a4, set4] = useState('Answer 4')

  let subTopicsArray = []

  for (const unit in APCALC) {
      subTopicsArray.push([APCALC[unit]["name"], APCALC[unit]["subTopics"]])
  }

  const metaTopics = [] //topics user initially selects from
  subTopicsArray.map((pairs) => metaTopics.push(pairs[0]))

  const subTopics = new Map()
  subTopicsArray.map((pair) => subTopics.set(pair[0], pair[1]))

  const [possibleTopics, setSelected] = useState(subTopicsArray[0][1]) //sub-topics that user is able to pick from

  const [checkedBoxes, setChecked] = useState([])
  const [solution, setSolution] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState(-1)
  const [solutionDialogToggle, setSolutionDialog] = useState(false)

  const handleChange = (e, newValue) => {
      setSelected(subTopics.get(newValue))
  }

  const handleTextChange = (e) => {
    setText(e.target.value)
  }

  const uploadImage = () => {
    if (imageFile == null) return;
    const imageRef = ref(storage, `${imageFile.name}`)
    uploadBytes(imageRef, imageFile).then(() => alert('Image Submitted'))
  }

  const handleClose = () => {
    setSolutionDialog(false)
  };    


  const handleSubmit = () => {

    if (qText === '') {
      alert("Please enter question text");
      return
    }

    if (correctAnswer === -1) {
      alert("Please select the correct answer from the multiple choice section below.");
      return
    }

    else {

      try {

      const subTopicID = checkedBoxes[0].split(' ')[0].replace('.', '0') //primary subTopic ID of the question, converted to Question ID
      const subTopicNums = checkedBoxes.map( (str) => str.split(' ')[0]) // list of subTopics nums, such as 1.4 or 5.7
      const units = subTopicNums.map( str => str.split('.')[0])
      
      let r = (Math.random() + 1).toString(36).substring(5);
      const courseLevel = units.includes('9') || units.includes('10') ? 2 : 1 //level 2 [BC] only if the question convers units 9 or 10

      const questionID = subTopicID.replace('.', '0') + 'APCALC' + r;

      addMCQuestion(qText,[a1,a2,a3,a4],correctAnswer,questionID,difficulty,solution,"AP","Calculus",courseLevel,units,subTopicNums, needCalculator, currentUser.uid);
      alert('Question successfully submitted, thank you!');  
      subTopicNums.forEach((subTopic) => updateNumQuestionsIndividually([subTopic]))
      }

      catch {
        alert("Please select at least one subtopic before submitting, and make sure you're logged in.");
      }

    }
}

  return (
    <Stack>
      <form noValidate autoComplete='off'>
        <input type="file" onChange={(event) => setImageUpload(event.target.files[0])}>
        </input>
        <Button onClick={uploadImage}>Upload Image (TEST)</Button>

        <TextField
            label='Question Text'
            variant='outlined'
            helperText='Write equations in LaTex and inside $#$, for example: $#x^2$'
            multiline
            rows={4}
            fullWidth
            required
            onChange={handleTextChange}
        />

        <TextField
            label='Solution'
            variant='outlined'
            multiline
            rows={4}
            fullWidth
            required
            onChange={(e) => setSolution(e.target.value)}
        />
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField label="Answer 1" id="outlined-size-normal" onChange={(e) => set1(e.target.value)} required/>
            <TextField label="Answer 2" id="outlined-size-normal" onChange={(e) => set2(e.target.value)} required/>
          </div>

          <div>
            <TextField label="Answer 3" id="outlined-size-normal" onChange={(e) => set3(e.target.value)} required/>
            <TextField label="Answer 4" id="outlined-size-normal" onChange={(e) => set4(e.target.value)} required/>
          </div>

          <FormGroup>
            <FormControlLabel control={<Checkbox/>} label="This question requires a calculator" onChange={(e) => setCalculator(e.target.checked)} />
          </FormGroup>

        </Box>
        <Stack spacing={1}>
          <Autocomplete
              options={metaTopics}
              renderInput={(params) => <TextField {...params}/>}
              disableClearable
              defaultValue={subTopicsArray[0][0]}
              onChange={handleChange}
              style={{ width: 300 }}
          />

          <Autocomplete
              multiple
              onChange={(e, selected) => setChecked(selected)}
              disableClearable
              id="checkboxes"
              options={possibleTopics}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                  <li {...props}>
                  <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                  />
                  {option}
                  </li>
              )}
              style={{ width: 300 }}
              renderInput={(params) => (
                  <TextField {...params} label="Sub-Topics" placeholder="All" />
              )}
          />

          <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={["easy", "medium", "hard"]}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Difficulty" />}
              onChange={(e, selected) => setDifficulty(selected)}
          />
        
        </Stack>

        <Typography> (Please select the correct answer below) What the student will see:</Typography>

        {needCalculator ? <CalculateIcon/> : null}

        <div>
        {qText.split('$').map((phrase) =>
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)}

        <RadioGroup>
            <FormControlLabel control={<Radio/>} label={a1.split('$').map((phrase) =>
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value='A1' onClick={(e) => setCorrectAnswer(0)}/>

            <FormControlLabel control={<Radio/>} label={a2.split('$').map((phrase) =>
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value='A2' onClick={(e) => setCorrectAnswer(1)}/>
            <FormControlLabel control={<Radio/>} label={a3.split('$').map((phrase) =>
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value='A3' onClick={(e) => setCorrectAnswer(2)}/>
            <FormControlLabel control={<Radio/>} label={a4.split('$').map((phrase) =>
          (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value='A4' onClick={(e) => setCorrectAnswer(3)}/>
        </RadioGroup>

        <Button onClick={() => setSolutionDialog(true)}>See Solution</Button>

        </div>

        <Dialog
        open={solutionDialogToggle}
        onClose={handleClose}
        //fullScreen
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Solution 
            </Typography>
                
            </Toolbar>
        </AppBar>

        <Stack spacing={2} direction='column' sx={{alignItems:"center"}}>
            {solution.split('$').map((phrase) => phrase[0] === "#" ? <Tex2SVG display='inline' latex={phrase.substring(1)} /> : <span>{phrase}</span>)}
        </Stack>

        </Dialog>

        <Button variant="contained" onClick={handleSubmit}>Submit Question</Button>


      </form>
    </Stack>
  )
}

export default MC
