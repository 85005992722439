import { Box, Tab, Paper, Stack, Button} from '@mui/material'
import {Typography, Link, TextField, Grid, Autocomplete, Dialog, Slide} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TabContext, TabList, TabPanel} from '@mui/lab'
import React, {useState, useEffect, forwardRef} from "react";
import {useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import {db} from "../../utils/firebase/firebase.utils";
import {collection, onSnapshot} from "firebase/firestore";
import ClassCard from '../../pages/home/ClassCard';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TeacherHome() {
    const { currentUser } = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('1')
    const [className, setClassName] = useState("")
    const [course, setCourse] = useState("AP Calc BC")
    const [userClasses, setClasses] = useState([])

    useEffect(() => 
    onSnapshot(collection(db, "UserCourseData"), (snapshot) => {
      setClasses(snapshot.docs.map((doc) => (doc.data())))
    }), []
    )

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }
 
    const handleClose = () => {
        setOpen(false);
      }
    
    return (
    <Box className="topbar">
        <Box textAlign='center'>
            <Button variant='contained' onClick={() => setOpen(true)}>
                Create New Class
            </Button>
        </Box>

        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create a class
            </Typography>
                
            </Toolbar>
        </AppBar>
        <Stack spacing={2} direction='column' sx={{alignItems:"center"}}>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Class Name"
                type=""
                variant="standard"
                onChange={(e) => setClassName(e.target.value)}
            />

            <Autocomplete 
                options={["AP Calculus BC","AP Calculus AB"]} 
                renderInput={(params) => <TextField {...params}/>}
                disableClearable
                defaultValue={"AP Calc BC"}
                onChange={(e, newValue) => setCourse(newValue)}
                style={{ width: 300 }}
            />

            <Button variant='outlined'>
                Submit
            </Button>               

        </Stack>

        </Dialog>   

        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label="Tabs" onChange={handleChange} centered>
                    <Tab label="My Classes" value='1' />
                </TabList>
            </Box>

            <TabPanel value='1' style={{display: 'flex', justifyContent:'center'}}>
                <Grid container spacing={3}>
                    {userClasses.filter((ele) => ele.uid === currentUser.uid).map(userClass => 
                    <Grid item xs={12} md = {6} lg = {4}>
                        <ClassCard className={userClass.className} courseName={userClass.courseName} link={"/"+userClass.courseCode+"/"+currentUser.uid}/>
                    </Grid>
                    )}
                </Grid>
            </TabPanel>


        </TabContext>

       
    </Box>
    )
}

export default TeacherHome;