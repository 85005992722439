import React, { useState, useEffect } from 'react';
import { Typography, Paper, useMediaQuery, Stack, Avatar, Button, Box, Divider, FormControl, Card, CardContent, Grid, CardActions, ListItemIcon, Select, MenuItem, InputLabel, Dialog, AppBar, Toolbar, List, ListItem, ListItemText, IconButton, Slide, TextField } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { css } from '@emotion/react';
import { alpha, styled } from '@mui/material/styles';
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/logo.svg';
import avatarlogo from '../../assets/dropdown.svg';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { Logout, Settings, PersonAdd, Home, AccountCircle, KeyboardArrowDown } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { updateUser } from '../../utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context.jsx';
import { logout } from "../../utils/firebase/firebase.utils";

import { useContext } from 'react';

const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
];

const LoginButton = styled(Button)({
    color: 'primary',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 1.5,
    marginLeft: "20px",
    marginRight: "20px",
    borderColor: 'black',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        borderColor: 'black',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: 'black',
    },
});

const MenuButton = styled(Button)({
    color: 'primary',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '3px 8px',
    border: '2px solid',
    lineHeight: 1.5,
    marginLeft: "20px",
    marginRight: "20px",
    borderColor: 'black',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        borderColor: 'black',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: 'black',
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: 700,
    width: 500,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'start',
    alignItems: 'start',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));

const StyledPaperFr = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: '100%',
    width: 500,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));

const StyledPaperDialog = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: '100%',
    width: 700,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MobileProfile = () => {
    const { currentUser } = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const [selectValue, setSelectValue] = React.useState(currentUser.country);


    const handleChange = (event) => {
        setSelectValue(event.target.value);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await updateUser(currentUser.uid, currentUser.email, data.get('name'), selectValue , data.get('school'));
        window.location.reload();
    };


    const Form = (
        <React.Fragment>
            <Stack component="form" id="userformdata" onSubmit={handleSubmit} sx={{ alignItems: 'center' }}>
                <StyledPaperFr>
                    <Stack direction="column" sx={{ alignItems: 'center' }}>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ m: 2 }}>My Profile</Typography>
                            <Avatar sx={{ height: 50, width: 50, mb: 4, border: '2px solid gray' }}></Avatar>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Name</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    name="name"
                                    id="outlined-required"
                                    label=""
                                    defaultValue={currentUser.name}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 4, mt: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectValue}
                                        defaultValue={currentUser.country}
                                        label="Country"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        {countryList.map(country =>
                                            <MenuItem value={country}>{country}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">School</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    name="school"
                                    id="outlined-required"
                                    label=""
                                    defaultValue={currentUser.school}
                                />
                            </Box>
                        </Stack>
                        <Button color="primary" autoFocus sx={{ color: 'white', display: 'flex', borderRadius: 3, mb: 3, mt: 3, minWidth: 100 }} variant="contained" type="submit" onClick={handleClose}>
                            Save
                        </Button>
                    </Stack>
                </StyledPaperFr>
            </Stack>
        </React.Fragment>
    );

    const DialogDesign = (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Profile
                        </Typography>
                        <Button autoFocus sx={{ color: 'white', display: 'flex' }} onClick={handleClose}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>

                {Form}
            </Dialog>
        </React.Fragment>
    );

    const personalInfo = (
        <Stack sx={{ alignItems: 'center' }}>
            <StyledPaperFr>
                <Stack sx={{ alignItems: 'center' }}>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="h4" sx={{ m: 3, color: 'primary.main' }} >Hi {currentUser.name}</Typography>
                        <Typography variant="h5" sx={{ m: 2 }}> My Profile</Typography>
                        <Avatar sx={{ height: 50, width: 50, mb: 4, border: '2px solid gray' }}></Avatar>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Name</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{currentUser.name}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Email</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{currentUser.email}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">School</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{currentUser.school}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Country</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{currentUser.country}</Typography>
                        </Box>
                    </Stack>
                    <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ color: 'white', mt: 3, mb: 3, borderRadius: 3, minWidth: 120 }}>Update</Button>
                    {DialogDesign}
                </Stack>
            </StyledPaperFr>
        </Stack>
    );

    return (
        <React.Fragment>
            <Stack spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {personalInfo}
            </Stack>
        </React.Fragment>
    )
}


export default MobileProfile;