import React, {useState} from 'react'
import {RadioGroup, FormControlLabel, Radio, Stack, Button, Dialog, Toolbar, IconButton, TextField, Typography, AppBar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalculateIcon from '@mui/icons-material/Calculate';
import Tex2SVG from "react-hook-mathjax";
import ApproveQuestion from "./approveQuestion.component"

function ShowQuestion({question}) {
    const [solutionDialogToggle, setToggle] = useState(false);

    const qText = question.text
    const answers = question.choices
    const correct = question.correctans
    const id = question.questionid
    const calculatorNeeded = question.calculator 
    const solution = question.solution
    

    //console.log(solution)
    const splitText = qText.split('$')

    const a1 = answers[0]
    const a2 = answers[1]
    const a3 = !answers[2].includes("(empty)") ? answers[2] : '$$'
    const a4 = !answers[3].includes("(empty)") ? answers[3] : '$$'

    const [selectedChoice, setSelected] = useState(null)

    const handleCheck = () => {
        console.log(`selected:${selectedChoice}, correct:${correct}`)
        //comparing string with int with ==
        if (selectedChoice == correct) {
            alert('Correct Answer!')
        }
        else {
            alert('Wrong Answer')
        }
    }
    /*
    const showSolution = () => {
        phrase[0] === "#" ? <Tex2SVG display='inline' latex={phrase.substring(1)} /> : <span>{phrase}</span>
    }
    */

    const handleClose = () => {
        setToggle(false)
    }

    return (
        <>

<Dialog
        open={solutionDialogToggle}
        onClose={handleClose}
        //TransitionComponent={Transition}
        fullScreen
        disableEscapeKeyDown={true}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Solution 
            </Typography>
                
            </Toolbar>
        </AppBar>

        <Stack spacing={2} direction='column' sx={{alignItems:"center"}}>
            {solution.split('$').map((phrase) => phrase[0] === "#" ? <Tex2SVG display='inline' latex={phrase.substring(1)} /> : <span>{phrase}</span>)}
        </Stack>

        </Dialog>

        
            {calculatorNeeded ? <CalculateIcon/> : null}

            <div>
                {
                splitText.map((phrase) => phrase[0] === "#" ? <Tex2SVG display='inline' latex={phrase.substring(1)} /> : <span>{phrase}</span>)
                }    
            </div>
            
            <Stack spacing={2}>
                <RadioGroup>
                    <FormControlLabel control={<Radio/>} label={a1.split('$').map((phrase) =>
                (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value={0} onClick={(e) => setSelected(e.target.value)} />

                    <FormControlLabel control={<Radio/>} label={a2.split('$').map((phrase) =>
                (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value={1} onClick={(e) => setSelected(e.target.value)} />
                    <FormControlLabel control={<Radio/>} label={a3.split('$').map((phrase) =>
                (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value={2} onClick={(e) => setSelected(e.target.value)} />
                    <FormControlLabel control={<Radio/>} label={a4.split('$').map((phrase) =>
                (phrase[0] === "#") ? <Tex2SVG display="inline" latex={phrase.substring(1)} /> : <span>{phrase}</span>)} value={3} onClick={(e) => setSelected(e.target.value)} />
                </RadioGroup>

                <Button onClick={() => handleCheck()}>
                    Check Answer
                </Button>

                <Button onClick={() => setToggle(true)}>
                    See solution
                </Button>

                <ApproveQuestion question={question}/>

            </Stack> 
                
            { /*
            answers.map((answer) => 
                answer.split('$').map((phrase) => phrase[0] === "#" ? <Tex2SVG latex={phrase.substring(1)} /> : <span>{phrase}</span>))
            */}


        </>
    )
}

export default ShowQuestion;
