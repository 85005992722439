import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Stack, Paper, Typography, AccordionSummary, AccordionDetails, Accordion, Button, Grid} from '@mui/material'
import DashboardTable from './dashboardtable.component'
import React, { useEffect, useState } from "react";
import { auth, db, logout, updateUser} from "../../utils/firebase/firebase.utils";
import {retrieveUserQuestionsbyUnit} from "../../utils/firebase/qbank.utils";

import { userEnrol, addMCQuestion,updateMCQuestion, addFRQuestion, checkIfQuestionIdExists,updateFRQuestion, retrieveQuestionsByUnitExceptList, solveQuestion, controlMCQuestion} from "../../utils/firebase/course.utils";
import { query, collection, getDocs, where, onSnapshot} from "firebase/firestore";
import APCALC from '../unitperclass/apcalc.json'
import {useContext } from 'react';
import { UserContext } from '../../contexts/user.context';


function Dashboard({classCode, uid}) {
  const { currentUser } = useContext(UserContext);
  const [userCourseData, setUserData] = useState([]); 

  /*
  useEffect(()=> 
    //console.log(retrieveQuestionsByUnitExceptList("AP","Biology","FR",["unitq"],[]));
    console.log(retrieveUserQuestionsbyUnit("Oki3qLJ3Qme8u9PF5mzeEGfDdHx2", "AP", "Biology", "FR", ["unitq"], "correct"))
   ,[]);
   */


  if (currentUser.uid === uid) {
    console.log("permission")
  }
  else {
    return <div>Zort</div>
  }
  
  let subTopicsArray = []

  const classData = userCourseData.filter((data) => data.uid === uid && data.courseCode === classCode)[0]
  console.log(classData)

  for (const unit in APCALC) {
      subTopicsArray.push([APCALC[unit]["name"], APCALC[unit]["subTopics"].map(ele => [ele, 0, 0, 200])])
  } 

    const exampleAssignments = [{name: 'Trigonometry', deadline:'20 May 2022', score: '0/20'},
                                {name: 'Derivatives', deadline:'25 May 2022', score: '100/100'}]

    return (
    <Stack spacing={2}>
        <Paper>
        <Typography variant="h2" color="initial">Hello {currentUser.displayName}</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                <Typography>Work Assigned</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DashboardTable rows={exampleAssignments} columnNames={['Name', 'Due Date', 'Score']}/>
                </AccordionDetails>
                </Accordion>
        </Paper> 

        <Paper elevation='8' variant='outlined' sx={{}}>
            <Typography variant='h2'>Skill Summary</Typography> 

            {subTopicsArray.map((pair) => 
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                <Typography>{pair[0]}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DashboardTable rows={pair[1]} columnNames={['Subtopic', 'Questions Solved', 'Correctly Solved', 'Questions Remaining']}/>
                </AccordionDetails>
                </Accordion>)}
        </Paper> 

    </Stack>
        
        )}

export default Dashboard;