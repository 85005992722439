import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {Card, CardActions, CardContent, Button, Typography, Box, Link} from '@mui/material'

function ClassCard({className, courseName, link}) {

  return (
    <Link underline='none' component={RouterLink} to={link}>
      <Card sx={{ minWidth: 50, cursor:'pointer'}}>
        <CardContent>
          <Typography variant="h5" component="div">
            {className}
          </Typography>
          <Typography sx={{}} color="text.secondary">
            {courseName}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Practice</Button>
        </CardActions>
      </Card>
    </Link>
  )
}

export default ClassCard