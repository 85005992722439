import {Stack, Autocomplete, Checkbox, Button, Typography,
    TextField, AppBar, Toolbar, IconButton, Slide, Grid, CircularProgress } from "@mui/material"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';    
import {useState, forwardRef, useEffect} from 'react'
import APCALC from '../unitperclass/apcalc.json'
import CloseIcon from '@mui/icons-material/Close';
import {retrieveUserQuestionsbyUnit} from "../../utils/firebase/qbank.utils";
import MultiQ from '../../components/practice/multiquestion.component';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomPractice() {
    const [questionList, setQuestionList] = useState(null);
    const [showMultiQ, setShowMultiQ] = useState(false)
    const [count, setCount] = useState(0)

    async function retrieveQuestions(){

      const q = await retrieveUserQuestionsbyUnit("eModMHsGUbPGMotnPrAHoBMBjtj2", "AP", "Calculus", "MC", ["10.14","1.15","6.6"], 10);
      setQuestionList(q);

      setInterval(() => {
          setCount(old => old + 1)
      }, 1000);

      setShowMultiQ(true);
    }

    //would be useful to have a glossary of what each topic means with example questions: Not sure what each topic may include? Check out our glossary for more information!
    let subTopicsArray = []

    for (const unit in APCALC) {
        subTopicsArray.push([APCALC[unit]["name"], APCALC[unit]["subTopics"]])
    }
    
    const Transition = forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });    

    const metaTopics = [] //topics user initially selects from
    subTopicsArray.map((pairs) => metaTopics.push(pairs[0]))

    const subTopics = new Map()
    subTopicsArray.map((pair) => subTopics.set(pair[0], pair[1]))

    const [possibleTopics, setSelected] = useState(subTopicsArray[0][1]) //sub-topics that user is able to pick from

    const [checkedBoxes, setChecked] = useState([])

    const [numQuestions, setNumQuestions] = useState(0)

    const handleChange = (e, newValue) => {
        setSelected(subTopics.get(newValue))
    }

    //incomplete
    const startPractice = () => {     
        //checkedBoxes: array of subTopic names; example:
        //'1.3 Estimating Limit Values from Graphs', '1.1 Introducing Calculus: Can Change Occur at an Instant?']
        
        //numQuestions: (string) number of questions selected
        if (!checkedBoxes.length) {
            alert('Please select at least one topic to study.')
        }
        
        else if (numQuestions <= 0) {
            alert('Please enter a valid number of questions')
        }

        else {
            retrieveQuestions()
        }
    }    

    console.log(questionList)

    return (
    //Topic selection
    <Stack spacing={2} width='250px' direction='column'>
            <Typography>Select Topics to Study</Typography>

            <Autocomplete 
                options={metaTopics} 
                renderInput={(params) => <TextField {...params}/>}
                disableClearable
                defaultValue={subTopicsArray[0][0]}
                onChange={handleChange}
                style={{ width: 300 }}
                />
            
            <Autocomplete
                multiple
                onChange={(e, selected) => setChecked(selected)}
                disableClearable
                id="checkboxes"
                options={possibleTopics}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={(e) => console.log(e)}
                    />
                    {option}
                    </li>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Sub-Topics" placeholder="All" />
                )}
            />

            <TextField type='number' label='Number of questions' onChange={(e) => setNumQuestions(e.target.value)}></TextField>

        <Button variant='contained' onClick={() => startPractice()}>Practice</Button> 
        {showMultiQ && !(count >= 1) ? <CircularProgress /> : <></>}
        {showMultiQ && count >= 1 ? <MultiQ questions={questionList}/> : <></>}

    </Stack>

    )
}

export default CustomPractice;