import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import LandingHomeMobile from './mobile';
import LandingHomeDesktop from './desktop';
  
const LandingHome = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return(
    <React.Fragment>
    {matches ? <LandingHomeMobile/> : <LandingHomeDesktop/>}
    </React.Fragment>
  )
}

export default LandingHome;