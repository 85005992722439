import React, {useState, useEffect} from 'react'
import {Typography, Button, Container, Autocomplete, TextField, RadioGroup, Radio, FormControlLabel, Accordion, AccordionSummary, AccordionDetails} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import MC from './multiplechoice.component'
import FR from './freeresponse.component'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {updateNumberofQuestions,updateNumberofQuestionsBySub,retrieveNumberofQuestionsBySub} from "../../utils/firebase/qbank.utils";
import {db} from "../../utils/firebase/firebase.utils";
import {doc, onSnapshot} from "firebase/firestore";

function SubmitQuestion() {
    const [questionData, setQuestionData] = useState('')

    useEffect(() =>
        onSnapshot(doc(db, "Meta", "APCalculus"), (snapshot) => {
            setQuestionData(snapshot.data())
        }), []
    )    


    const columns = [
        { field: 'sub', headerName: 'Subtopic', width: 500 },
        {
        field: 'numQ',
        headerName: 'Number of Questions',
        type: 'number',
        width: 150,
        },
    ];
        
    const rows = [];
    let counter = 0;

    for (const unit in questionData) {
        for (const subTopic in questionData[unit]) {
            rows.push({id: counter, sub:`${unit}.${subTopic}`, numQ:questionData[unit][subTopic]});
            counter++;
        }
    }      
            
    const [qType, setType] = useState('MC')

    return (
        <Container spacing={4}>
            <Accordion>
                <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                {<ExpandMoreIcon />}
                <Typography>See Submitted Questions by Subtopic</Typography>
                </AccordionSummary>
                <AccordionDetails>

                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        checkboxSelection={false}
                    />
                </div>
                
                </AccordionDetails>
            </Accordion>
            
            
            <Typography>Input Question</Typography>

            {/* Select multiple choice or free response */}
            <RadioGroup value={qType} onChange={(e) => setType(e.target.value)}>
                <FormControlLabel control={<Radio/>} label='Multiple Choice' value='MC'/>
                <FormControlLabel control={<Radio/>} label='Free Response' value='FR'/>
            </RadioGroup>
        
            {qType === 'MC' && <MC/>}
            {qType === "FR (Doesn't work right now)" && <FR/>}

        </Container>
    )
}

export default SubmitQuestion