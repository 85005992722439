import {retrieveUserQuestionsbyUnit, solveQuestion} from "../../utils/firebase/qbank.utils";
import {addMCQuestion} from "../../utils/firebase/course.utils";
import {updateNumQuestionsIndividually,updateNumberofQuestions,updateNumberofQuestionsBySub,retrieveNumberofQuestionsBySub} from "../../utils/firebase/qbank.utils";

import {createPracticeTest} from "../../utils/firebase/practicetest.utils";
import React, { useEffect } from "react";
import  Button from '@mui/material/Button';
import {Stack, Typography}from '@mui/material/';
import {useContext, useState} from 'react';
import { UserContext } from '../../contexts/user.context';

import MultiQ from '../../components/practice/multiquestion.component';

import {collection, onSnapshot, getDocs} from "firebase/firestore";
import {db} from "../.././utils/firebase/firebase.utils";

const Deneme = () => {
  
  const [questionList, setQuestionList] = useState([]);
  
  
    async function questionRetrieve(){

      const deneme = await retrieveUserQuestionsbyUnit("eModMHsGUbPGMotnPrAHoBMBjtj2", "AP", "Calculus", "MC", ["10.14","1.15","6.6"], 10);
      setQuestionList(deneme);
      console.log(questionList);
    }




  const questions = [{text: "$#lim_{x \\rightarrow \\infty} \\frac{3-x^3}{9+3^x}$",correctans:1, choices: ["$#\\frac{1}{3}$", "$#0$", "$#3$", "nonexistent"], questionid:"1015APCALClburdg6", calculator:false, solution:"asd"},
  {text: "(2) $#lim_{x \\rightarrow \\infty} \\frac{3-x^3}{9+3^x}$",correctans:1, choices: ["$#\\frac{1}{3}$", "$#0$", "$#3$", "nonexistent"], questionid:"1015APCALClburdg6", calculator:true, solution:"asd"}]

  return (
      <Stack spacing={2}>
        <MultiQ questions={questions}/>
        <Button onClick={()=>{questionRetrieve()}}>Merhaba</Button>
      </Stack>
      )
}




export default Deneme;
