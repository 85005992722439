import React from 'react';
import Profile from '../../components/profile';



export default function ProfilePage(){

  return(
    <React.Fragment>
      <Profile/>
    </React.Fragment>
  )
}